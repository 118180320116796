import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import { Waypoint } from 'react-waypoint';
import magnify from '../../content/assets/search.svg'
import filter from "../../content/assets/filter.svg"
import close from "../../content/assets/x.svg"
import uniq from 'lodash/uniq';

export default () => {

const [isOpen, setIsOpen] = useState(false);
const determine = ({previousPosition, currentPosition }) => {
    if ( currentPosition ===  Waypoint.above ) {
      setIsOpen(!isOpen);
    } 
    if ( previousPosition ===  Waypoint.above ) {
      setIsOpen(!isOpen);
    }
}


const [search, setSearch] = useState('');
const onFousSearch = (e) => {
    e.preventDefault(); 
    setSearch('');
}
const updateSearch = (e) => {
    setSearch( e.target.value.substr(0,40) );
}

const reduceSearch = (e) => {
  e.preventDefault(); 
  e.target.innerHTML.trim() === 'All' ? setSearch('') : setSearch(e.target.innerHTML.trim())
}



const data = useStaticQuery(graphql`
    query HistoryQuery {
        allHistoryJson {
    edges {
      node {
          id
          date
          history
      }
    }
  }
  }
  `)

  const sugarhistory = data.allHistoryJson.edges;
  let newsugarhistory = sugarhistory.filter( ( {node} ) =>  {
    if ( node  ) {
      return node.date.toLowerCase().includes(search.toLowerCase()) || node.history.toLowerCase().includes(search.toLowerCase())
    }  else { return node }
  });


 
  let list = sugarhistory.map( ({ node }) =>  { return node.date }  )
  const uniqList = uniq(list).sort();
  const [isList, setIsList] = useState(false);

  const toggleList = () => {
    setIsList(!isList);
  }

 
  return ( 
    <Layout>


    <section className="w-100 bg-white cf bt bb b--black-05 relative cf">
    <div className="ph3 ph4-ns pv1">
    <div className="mw9 center cf">

          <div className="w-100  h3">
            <div  className={ isOpen ? 'animated slideInDown fixed top-0 left-0 z-999 w-100 bt bb b--black-05 bg-white ' : 'db' } >
              <div id="searchbar" className="w-100  flex bg-white ph3">
                <div className="w-100 flex items-center ">
                  
                  <img onClick = {onFousSearch} className="square-1-5 mr4" src={magnify} alt="search icon"/>
                    <input  
                    id="searchInput"
                    value={search}
                    onChange = {updateSearch} 
                    onClick = {onFousSearch}
                    className="w-100 bn h3 " type="search" placeholder="Search by Date or History"  />
                    <img onClick = {toggleList} className="square-2-5 pointer dim" src={filter} alt='menu'/> 

                </div>
            </div>
            </div>
          </div>

                <div  className={ isList ? 'shadow-5  fixed top-0 right-0 bottom-0 vh-100 overflow-y-scroll momentum-scroll overflow-hidden  animated bg-white slideInRight z-9999 faster  w-100 w-third-ns  ' : 'dn' } >
                  <div className="w-100  ph4  bg-white  bl b--black-05">
                    <ul className="list pa0 ma0 pb4 br2-ns ">
                      <li className="f6 fw5 pt4 ph3-ns theme-menu ttu tracked-lg" onMouseUp={toggleList} >filter by category</li>
                      <li className="f6 fw5 pt4 ph3-ns" onMouseUp={toggleList} ><img className="square-1 fr pointer dim" src={close} alt='menu'/> </li>
                      <li className="pointer ph3-ns ph0-ns pt3"> <div className="f6 fw4 link black-70 pointer ttc" onClick={reduceSearch} onMouseUp={toggleList}>All</div></li>
                      { uniqList.map(
                        (x) =>  <li key={x} className={"ph3-ns pv2 "}> <div  className="f6 pointer link black-70 fw4 ttc dim " onClick={reduceSearch} onMouseUp={toggleList}> {x} </div> </li>  )
                      }
                     <li className="h4 cf "></li>
                    </ul> 
                  </div>
                </div>



      </div>
      </div>
      </section>






    <div className="w-100 cf bg-theme-50">
    <div className="ph3 ph4-ns pv1">


    <div className="w-100 f4 fw5 pb2  pt4"></div>

    <Waypoint onPositionChange={determine}  /> 


    <div className="w-100  pb4 cf mb6">   
        <div className="w-100 pt3-ns pb8">
            {newsugarhistory.map(({ node }) => 
            <div className="w-100 cf" key={node.id}>
                <div className="flex items-start  mw6 center grow">
                { node.date ? 
                <div className="mw4 mr4"> 
                <div className="br-100 ba bw1 b--white-60 square-5 f5 fw5 white bg-black-20 flex items-center flex-hv-center tracked-lg">{node.date} </div> 
                <div className="h3 w2 br b--white-60  ml2"></div>
                </div> : ''}  
                { node.history ? <div className="w-75"> <div className="f5 tracked-sm fw3 white-80 pb2 lh-1-6 pt3"> {node.history} </div> </div> : ''}  
                </div>
            </div>
            )}
        </div> 
    </div> 
      
    </div>

    </div>
    </Layout>
  ) 

}


